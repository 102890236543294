import React from 'react';
import { Helmet } from 'react-helmet';

function ThankYouScript() {
    return (
        <div>
            {/* Other content */}
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11108051663"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11108051663');
            gtag('config', 'AW-11108051663/F4tqCMn4gPoDEM_V3bAp', {
              'phone_conversion_number': '0416 141 221'
            });
          `}
                </script>
                <script>
                    {`
            // Event snippet for Contact Form Submission conversion page
            gtag('event', 'conversion', {'send_to': 'AW-11108051663/wUYHCM-VhpEYEM_V3bAp'});
            console.log("thankYou script")
          `}
                </script>
            </Helmet>
        </div>
    );
}

export default ThankYouScript;
